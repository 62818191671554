<script setup>
import { ref, onMounted, onBeforeUnmount, computed } from "vue";
import { useLayout } from "@/layout/composables/layout";
import { useRouter } from "vue-router";
import { usePrimeVue } from "primevue/config";
import { useUserSession } from "@/stores/userSession";
import { storeToRefs } from "pinia";

const userSession = useUserSession();
const { user } = storeToRefs(userSession);

const $primevue = usePrimeVue();

defineExpose({
  $primevue,
});

const { onMenuToggle, layoutConfig, tabs, closeTab } = useLayout();

const outsideClickListener = ref(null);
const topbarMenuActive = ref(false);
const searchActive = ref(false);

const router = useRouter();

const activateSearch = () => {
  searchActive.value = true;
  setTimeout(() => {
    const input = document.querySelector(".searchInput");
    input.focus();
  }, 100);
};

const deactivateSearch = () => {
  searchActive.value = false;
};
const onCloseTab = (index) => {
  if (tabs.value.length > 1) {
    if (index === tabs.value.length - 1)
      router.push(tabs.value[tabs.value.length - 2].to);
    else router.push(tabs.value[index + 1].to);
  } else {
    router.push("/");
  }
  closeTab(index);
};

const logo = computed(() => {
  const path = "/layout/images/logo-";
  let logo;
  if (
    layoutConfig.layoutTheme.value === "primaryColor" &&
    layoutConfig.theme.value !== "yellow"
  ) {
    logo = "light.png";
  } else {
    logo =
      layoutConfig.colorScheme.value === "light" ? "dark.png" : "light.png";
  }
  return path + logo;
});

onMounted(() => {
  bindOutsideClickListener();
});

onBeforeUnmount(() => {
  unbindOutsideClickListener();
});

const bindOutsideClickListener = () => {
  if (!outsideClickListener.value) {
    outsideClickListener.value = (event) => {
      if (isOutsideClicked(event)) {
        topbarMenuActive.value = false;
      }
    };
    document.addEventListener("click", outsideClickListener.value);
  }
};
const unbindOutsideClickListener = () => {
  if (outsideClickListener.value) {
    document.removeEventListener("click", outsideClickListener);
    outsideClickListener.value = null;
  }
};
const isOutsideClicked = (event) => {
  if (!topbarMenuActive.value) return;

  const sidebarEl = document.querySelector(".layout-topbar-menu");
  const topbarEl = document.querySelector(".layout-topbar-menu-button");

  return !(
    sidebarEl.isSameNode(event.target) ||
    sidebarEl.contains(event.target) ||
    topbarEl.isSameNode(event.target) ||
    topbarEl.contains(event.target)
  );
};
const onMenuButtonClick = () => {
  onMenuToggle();
};

const handleLogout = () => {
  userSession.logoutUser();
  router.push("/login");
};
const route = useRoute();
const home = { icon: 'pi pi-home', to: '/' };
const breadcrumbRoutes = ref([]);
const breadcrumbRouteName = ref([]);

const setBreadcrumbRoutes = () => {
  if (route.meta.breadcrumb) {
    breadcrumbRoutes.value = route.meta.breadcrumb;
    return;
  }

  breadcrumbRoutes.value = route.fullPath
    .split('/')
    .filter((item) => item !== '')
    .filter((item) => isNaN(Number(item)))
    .map((item) => item.charAt(0).toUpperCase() + item.slice(1));
};

watch(
  route,
  () => {
    setBreadcrumbRoutes();
  },
  { immediate: true }
);
</script>

<template>
  <div class="layout-topbar">
    <router-link to="/ps-customer-server/list" class="app-logo">
      <img alt="app logo" :src="logo" />
      <span class="app-name">CapMagic</span>
    </router-link>

    <button ref="menubutton" class="topbar-menubutton p-link" type="button" @click="onMenuButtonClick">
      <span></span>
    </button>
    <div class="flex w-full justify-content-between align-items-center my-0 mr-4 ml-7">
      <nav class="layout-breadcrumb">
        <ol>
          <li>
            <router-link :to="home.to" style="color: inherit">
              <i :class="home.icon"></i>
            </router-link>
          </li>
          <li class="layout-breadcrumb-chevron">/</li>
          <template v-for="(breadcrumbRoute, i) in breadcrumbRoutes" :key="breadcrumbRoute">

            <li v-if="breadcrumbRoutes.length - 1 != i">
              <router-link :to="{ name: breadcrumbRoute.name }">
                {{ breadcrumbRoute.title }}
              </router-link>
            </li>
            <li v-else>{{ breadcrumbRoute.title }}</li>
            <li v-if="i !== breadcrumbRoutes.length - 1" class="layout-breadcrumb-chevron">/</li>
          </template>
        </ol>
      </nav>
      <div class="topbar-profile">
        <button class="topbar-profile-button p-link" type="button" v-styleclass="{
          selector: '@next',
          enterClass: 'hidden',
          enterActiveClass: 'scalein',
          leaveToClass: 'hidden',
          leaveActiveClass: 'fadeout',
          hideOnOutsideClick: 'true',
        }">
          <!-- <img alt="avatar" src="/layout/images/avatar.png" /> -->
          <Avatar label="SE" size="large" shape="circle" />
          <span class="profile-details ml-2">
            <span class="profile-name">{{ user.fullName }}</span>
            <!-- <span class="profile-job">{{ user.email }}</span> -->
          </span>
          <i class="pi pi-angle-down"></i>
        </button>
        <ul
          class="list-none p-3 m-0 border-round shadow-2 hidden absolute surface-overlay origin-top w-full sm:w-12rem mt-2 right-0 top-auto">
          <li>
            <a v-ripple @click="handleLogout"
              class="p-ripple flex p-2 border-round align-items-center hover:surface-hover transition-colors transition-duration-150 cursor-pointer">
              <i class="pi pi-power-off mr-3"></i>
              <span>Sign Out</span>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
